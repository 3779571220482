import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Badge } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { Col, Offcanvas, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/esm/Image";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../Hooks/useLogout";
import { getSearchBar, getSingleUser } from "../../api/products";
import CartPreview from "../Content/CartContent/CartPreview";
import ReactGA from "react-ga4";
import JWTDecoder from "../Utilities/JWTDecoder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faFile,
  faMoneyBill1,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBullhorn,
  faCashRegister,
  faRightLeft,
  faThLarge,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { exceptThisSymbolsSearchBars } from "../Utilities/exceptionSymbols";

var searchWord = "";
var filteredData = [];

function MainNavbar() {
  const cart_v2 = useSelector((state) => state.consumerCart.shoppingCart);
  //console.log("object")
  //console.log(cart_v2);
  const cartCount = getTotal();
  const [showCartPreview, setShowCartPreview] = useState(true);
  const [delayHandler, setDelayHandler] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState(-1);
  const { logout } = useLogout();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [points, setPoints] = useState("");

  var userData = null;

  const userHolder = JSON.parse(localStorage.getItem("user"));

  if (userHolder !== null) {
    const token = userHolder.token;
    userData = JWTDecoder(token);
  }

  function getTotal() {
    let totalItems = 0;
    cart_v2.forEach((item) => {
      totalItems =
        parseInt(totalItems) + parseInt(item.cardDetails.cardQuantity);
    });

    return totalItems;
  }

  const setUserInformation = async () => {
    const userHolder = JSON.parse(localStorage.getItem("user"));

    let userDataq = "";
    if (userHolder !== null) {
      const token = userHolder.token;
      userDataq = JWTDecoder(token);
      let data = await getSingleUser(userDataq.id);
      setPoints(
        data.rewardsInformation.buyingPoints +
          data.rewardsInformation.sellingPoints
      );
    }

    return;
  };

  const handleClick = () => {
    logout();
    navigate("/");
    window.location.reload(false);
  };

  //console.log(searchWord);

  const { data, status } = useQuery({
    queryKey: ["searchbar", "mainSearch"],
    queryFn: () => getSearchBar(searchWord),
  });

  const handleFilter = (event) => {
    event.preventDefault();
    setSelectedItem(-1);
    searchWord = event.target.value;
    if (searchWord.includes("+")) {
      searchWord.replace("+","")
    }
    if (searchWord.includes("[")) {
      searchWord.replace("[","")
    }
    if (searchWord.includes("%")) {
      searchWord.replace("%","")
    }
    if (searchWord.includes("*")) {
      searchWord.replace("*","")
    }
    if (searchWord.includes("(")) {
      searchWord.replace("(","")
    }
    if (searchWord.includes(")")) {
      searchWord.replace(")","")
    }
    if (searchWord.includes("<")) {
      searchWord.replace("<","")
    }
    if (searchWord.includes(">")) {
      searchWord.replace(">","")
    }
    setSearchQuery(searchWord);
    queryClient.resetQueries({
      queryKey: ["searchbar", "mainSearch"],
      exact: true,
    });
    if (searchWord.trim() === "") {
      filteredData = [{}];
    }
  };

  const setCards = () => {
    var searchbarResults = data.map((item) => {
      if (item[1] === "set") {
        item = { name: item[0], type: "Set" };
        return item;
      } else {
        item = { name: item, type: "Product" };
        return item;
      }
    });

    const newElement = [{ name: "Keyword Search", type: "Product" }];

    if (searchQuery.length > 2 && searchbarResults.length > 0) {
      searchbarResults = [...newElement, ...searchbarResults];
    }

    filteredData = searchbarResults;
  };

  if (status === "success") {
    setCards();
  }

  let cartPreviewRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!cartPreviewRef.current.contains(e.target)) {
        setShowCartPreview(true);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    setUserInformation();
  }, []);

  return (
    <>
      {["lg"].map((expand) => (
        <Navbar key={expand} expand={expand} className="mb-3 " sticky="top">
          <Container className=" justify-content-lg-end navbar" fluid>
            <Navbar.Brand href="/">
              <Image
                src={
                  "https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Logos+(SVG)/OddEgg_WhiteStroke.svg"
                }
                alt="OddEgg Logo"
                className="logo d-lg-block d-none"
                onError={(event) => {
                  event.target.src =
                    "https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/404.png";
                  event.onerror = null;
                }}
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-md-1`}
              className="me-2 d-inline-block d-lg-none"
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-md-1`}
              aria-labelledby={`offcanvasNavbarLabel-expand-md-1`}
              placement="start"
              className="d-lg-none"
            >
              <Offcanvas.Header
                style={{ backgroundColor: "#ffb6ce" }}
                closeButton
              ></Offcanvas.Header>
              <Offcanvas.Body style={{ backgroundColor: "#ffb6ce" }}>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="/" className="d-inline-block navHeader">
                    Home
                  </Nav.Link>

                  <NavDropdown
                    title="Cards"
                    className="d-inline-block navHeader"
                  >
                    <NavDropdown.Item href="/products" className="navSubHeader">
                      Pokemon
                    </NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Link
                    href="/buylist"
                    className="d-inline-block navHeader"
                  >
                    Buylist
                  </Nav.Link>

                  <NavDropdown
                    title="About Us"
                    className=" d-inline-block navHeader"
                  >
                    <NavDropdown.Item
                      href="/about/FAQ"
                      className="navSubHeader"
                    >
                      FAQ
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>

            <Navbar.Brand href="/" className="me-5 d-lg-none d-inline-block">
              <Image
                src={
                  "https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Logos+(SVG)/OddEgg_WhiteStroke.svg"
                }
                alt="OddEgg Logo"
                className="logoMobile d-lg-none d-inline-block"
                onError={(event) => {
                  event.target.src =
                    "https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/404.png";
                  event.onerror = null;
                }}
              />
            </Navbar.Brand>

            {/**Mobile App Bar*/}
            <Nav className=" justify-content-end  d-inline-block d-lg-none navbarNav">
              <NavDropdown
                title={<PersonIcon className="" />}
                className="d-inline-block "
                style={{}}
                drop="start"
              >
                {userHolder && (
                  <div>
                    <NavDropdown.Header className="navSubHeader">
                      Welcome back <span>{userData.firstName}!</span>
                    </NavDropdown.Header>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      href="/users/accounthome"
                      className="navSubHeader"
                    >
                      Account
                    </NavDropdown.Item>
                    {/*<NavDropdown.Item href="/users/watchlist">
                      Watch List
                </NavDropdown.Item>*/}
                {/**<NavDropdown.Item disabled className="navSubHeader">
                      Reward Points: {points}
                    </NavDropdown.Item> */}
                    
                    <NavDropdown.Item
                      className="navSubHeader"
                      onClick={handleClick}
                    >
                      <div>
                        <LogoutIcon />
                        <span className="p-2 navSubHeader">Logout</span>
                      </div>
                    </NavDropdown.Item>
                    <div>
                      {(userData.role === "Admin" ||
                        userData.role === "Trader") && (
                        <div>
                          <NavDropdown.Divider />
                          <NavDropdown.Item
                            href="/trade"
                            className="navSubHeader"
                          >
                            <div>
                              <FontAwesomeIcon icon={faRightLeft} />
                              <span className="p-2 navSubHeader">
                                Trade Portal
                              </span>
                            </div>
                          </NavDropdown.Item>
                        </div>
                      )}
                    </div>
                    <div>
                      {(userData.role === "Admin" ||
                        userData.role === "POS User") && (
                        <div>
                          <NavDropdown.Divider />
                          <NavDropdown.Item
                            href="/admin/POS"
                            className="navSubHeader"
                          >
                            <div>
                              <FontAwesomeIcon icon={faCashRegister} />
                              <span className="p-2 navSubHeader">POS</span>
                            </div>
                          </NavDropdown.Item>
                        </div>
                      )}
                    </div>
                    <div>
                      {userData.role === "Admin" && (
                        <div>
                          <NavDropdown.Divider />
                          <NavDropdown.Item disabled className="navSubHeader">
                            Admin Items
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/dashboard">
                            <div>
                              <FontAwesomeIcon icon={faThLarge} />
                              <span className="p-2 navSubHeader">
                                Dashboard
                              </span>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/inventory">
                            <div>
                              <FontAwesomeIcon icon={faWarehouse} />
                              <span className="p-2 navSubHeader">
                                Inventory
                              </span>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/orders">
                            <div>
                              <FontAwesomeIcon icon={faCreditCard} />
                              <span className="p-2 navSubHeader">Orders</span>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/transactions">
                            <div>
                              <FontAwesomeIcon icon={faFile} />
                              <span className="p-2 navSubHeader">
                                Transactions
                              </span>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/autoUpdate">
                            <div>
                              <FontAwesomeIcon icon={faMoneyBill1} />
                              <span className="p-2 navSubHeader">
                                Auto Price Update
                              </span>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/marketing">
                            <div>
                              <FontAwesomeIcon icon={faBullhorn} />
                              <span className="p-2 navSubHeader">
                                Marketing
                              </span>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/users">
                            <div>
                              <FontAwesomeIcon icon={faUser} />
                              <span className="p-2 navSubHeader">
                                User Management
                              </span>
                            </div>
                          </NavDropdown.Item>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!userHolder && (
                  <div>
                    <NavDropdown.Item href="/users/signup" className="">
                      New?
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/users/login" className="">
                      Sign In
                    </NavDropdown.Item>
                  </div>
                )}
              </NavDropdown>

              <Nav.Link href="/cart" className="d-inline-block m-3">
                {cartCount.length === 0 ? (
                  <ShoppingCartIcon />
                ) : (
                  <Badge color="error" badgeContent={cartCount}>
                    <ShoppingCartIcon />
                  </Badge>
                )}
              </Nav.Link>
            </Nav>

            {/**Desktop App Bar*/}
            <Nav className="justify-content-start flex-grow-1 pe-1 navbarNav">
              <Nav.Link href="/" className=" d-lg-block d-none navHeader">
                Home
              </Nav.Link>
              <NavDropdown
                title="Cards"
                className=" d-lg-block d-none navHeader"
              >
                <NavDropdown.Item href="/products" className="navSubHeader">
                  Pokemon
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/buylist" className="d-lg-block d-none navHeader">
                Buylist
              </Nav.Link>
              <NavDropdown
                title="About Us"
                className=" d-lg-block d-none navHeader"
              >
                <NavDropdown.Item href="/about/FAQ" className="navSubHeader">
                  FAQ
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>

            <Form
              className="typeahead-form-group flex-grow-1 justify-content-end align-content-end"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <Form.Control
                type="text"
                autoComplete="off"
                onChange={handleFilter}
                placeholder="Search"
                aria-describedby="basic-addon1"
                onKeyDown={(e) => {
                  exceptThisSymbolsSearchBars.includes(e.key) &&
                    e.preventDefault();

                  console.log(e.key);
                  console.log(e.target.value.length);

                  if (e.key === "ArrowUp" && filteredData.length > 0) {
                    if (selectedItem > -1) {
                      setSelectedItem(selectedItem - 1);
                    }
                  }

                  if (e.key === "ArrowDown" && filteredData.length > 0) {
                    if (selectedItem === null) {
                      setSelectedItem(0);
                    }

                    if (selectedItem < filteredData.length) {
                      setSelectedItem(selectedItem + 1);
                    }
                  }

                  if (e.key === "Enter" && e.target.value.length > 0) {
                    console.log(selectedItem);
                    console.log(searchQuery);
                    if (selectedItem === null || selectedItem === 0 || selectedItem < 0) {
                      navigate(`/products/${searchQuery}`);
                      window.location.reload(false);
                    } else {
                      if (filteredData[selectedItem].type === "Set") {
                        ReactGA._gtag("event", "search", {
                          search_term: filteredData[selectedItem].name,
                        });
                        navigate(
                          `/products/set/${filteredData[selectedItem].name}`
                        );
                        window.location.reload(false);
                      } else {
                        ReactGA._gtag("event", "search", {
                          search_term: filteredData[selectedItem].name,
                        });
                        navigate(
                          `/products/${filteredData[selectedItem].name}`
                        );
                        window.location.reload(false);
                      }
                    }
                  }
                }}
              />

              <ListGroup className="typeahead-list-group">
                {filteredData.length > 0 &&
                  filteredData.map((product, index) => {
                    console.log(index);
                    console.log(selectedItem);
                    if (index === 0 && searchQuery.length > 2) {
                      return (
                        <ListGroup.Item
                          key={index}
                          className={
                            selectedItem === index
                              ? "typeahead-list-group-item_active"
                              : "typeahead-list-group-item"
                          }
                          action
                          onClick={() => {
                            ReactGA._gtag("event", "search", {
                              search_term: filteredData[0].name,
                            });
                          }}
                          href={`/products/${searchQuery}`}
                        >
                          <div>
                            <span>Search for "{searchQuery}"</span>
                            <br />
                          </div>
                        </ListGroup.Item>
                      );
                    } else {
                      if (product.type === "Set") {
                        return (
                          <ListGroup.Item
                            key={index}
                            className={
                              selectedItem === index
                                ? "typeahead-list-group-item_active"
                                : "typeahead-list-group-item"
                            }
                            action
                            onClick={() => {
                              ReactGA._gtag("event", "search", {
                                search_term: filteredData[0].name,
                              });
                            }}
                            href={`/products/set/${product.name}`}
                          >
                            <div>
                              <span>{product.name}</span>
                              <br />
                              <small className=" fst-italic">
                                {product.type}{" "}
                              </small>
                            </div>
                          </ListGroup.Item>
                        );
                      } else {
                        return (
                          <ListGroup.Item
                            key={index}
                            className={
                              selectedItem === index
                                ? "typeahead-list-group-item_active"
                                : "typeahead-list-group-item"
                            }
                            action
                            onClick={() => {
                              ReactGA._gtag("event", "search", {
                                search_term: filteredData[0].name,
                              });
                            }}
                            href={`/products/${product.name}`}
                          >
                            {product.name}
                          </ListGroup.Item>
                        );
                      }
                    }
                  })}
              </ListGroup>
            </Form>

            <Nav className="justify-content-end flex-grow-1 pe-5 me-5">
              <NavDropdown
                title={<PersonIcon />}
                className=" d-lg-block d-none"
                drop="start"
              >
                {userHolder && (
                  <div>
                    <NavDropdown.Header
                      className="navSubHeader me-1"
                      style={{ fontSize: "11pt" }}
                    >
                      Welcome back <span>{userData.firstName}!</span>
                    </NavDropdown.Header>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      href="/users/accounthome"
                      className="navSubHeader"
                    >
                      Account
                    </NavDropdown.Item>
                    {/*<NavDropdown.Item href="/users/watchlist">
                      Watch List
                </NavDropdown.Item>*/}
                {/**<NavDropdown.Item disabled className="navSubHeader">
                      Reward Points: {points}
                    </NavDropdown.Item> */}
                    
                    <NavDropdown.Item
                      onClick={handleClick}
                      className="navSubHeader"
                    >
                      <div>
                        <LogoutIcon />
                        <span className="p-2 navSubHeader">Logout</span>
                      </div>
                    </NavDropdown.Item>
                    <div>
                      {(userData.role === "Admin" ||
                        userData.role === "Trader") && (
                        <div>
                          <NavDropdown.Divider />
                          <NavDropdown.Item
                            href="/trade"
                            className="navSubHeader"
                          >
                            <div>
                              <FontAwesomeIcon icon={faRightLeft} />
                              <span className="p-2 navSubHeader">
                                Trade Portal
                              </span>
                            </div>
                          </NavDropdown.Item>
                        </div>
                      )}
                    </div>
                    <div>
                      {(userData.role === "Admin" ||
                        userData.role === "POS User") && (
                        <div>
                          <NavDropdown.Divider />
                          <NavDropdown.Item
                            href="/admin/POS"
                            className="navSubHeader"
                          >
                            <div>
                              <FontAwesomeIcon icon={faCashRegister} />
                              <span className="p-2 navSubHeader">POS</span>
                            </div>
                          </NavDropdown.Item>
                        </div>
                      )}
                    </div>
                    <div>
                      {userData.role === "Admin" && (
                        <div>
                          <NavDropdown.Divider />
                          <NavDropdown.Item disabled className="navSubHeader">
                            Admin Items
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/dashboard">
                            <div>
                              <FontAwesomeIcon icon={faThLarge} />
                              <span className="p-2 navSubHeader">
                                Dashboard
                              </span>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/inventory">
                            <div>
                              <FontAwesomeIcon icon={faWarehouse} />
                              <span className="p-2 navSubHeader">
                                Inventory
                              </span>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/orders">
                            <div>
                              <FontAwesomeIcon icon={faCreditCard} />
                              <span className="p-2 navSubHeader">Orders</span>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/transactions">
                            <div>
                              <FontAwesomeIcon icon={faFile} />
                              <span className="p-2 navSubHeader">
                                Transactions
                              </span>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/autoUpdate">
                            <div>
                              <FontAwesomeIcon icon={faMoneyBill1} />
                              <span className="p-2 navSubHeader">
                                Auto Price Update
                              </span>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/marketing">
                            <div>
                              <FontAwesomeIcon icon={faBullhorn} />
                              <span className="p-2 navSubHeader">
                                Marketing
                              </span>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/users">
                            <div>
                              <FontAwesomeIcon icon={faUser} />
                              <span className="p-2 navSubHeader">
                                User Management
                              </span>
                            </div>
                          </NavDropdown.Item>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!userHolder && (
                  <div>
                    <NavDropdown.Item href="/users/signup">
                      New?
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/users/login">
                      Sign In
                    </NavDropdown.Item>
                  </div>
                )}
              </NavDropdown>
              <Nav.Link
                href="/cart"
                className=" d-lg-block d-none"
                onMouseEnter={(e) => {
                  setDelayHandler(
                    setTimeout(() => {
                      setShowCartPreview(false);
                    }, 1000)
                  );
                }}
                onMouseLeave={() => {
                  clearTimeout(delayHandler);
                }}
              >
                {cartCount.length === 0 ? (
                  <div className=" d-lg-block d-none">
                    <ShoppingCartIcon />
                  </div>
                ) : (
                  <>
                    <div className=" d-lg-block d-none">
                      <Badge color="error" badgeContent={cartCount}>
                        <ShoppingCartIcon />
                      </Badge>
                    </div>
                  </>
                )}
              </Nav.Link>
              <div hidden={showCartPreview}>
                <div
                  className="d-lg-block d-none cartPreview"
                  ref={cartPreviewRef}
                >
                  <Container style={{ overflowY: "scroll", height: "380px" }}>
                    <Row>
                      <Col>
                        <div className=" d-flex">
                          <h3
                            className="subheader mt-1"
                            style={{ fontSize: "16pt" }}
                          >
                            Shopping Cart
                          </h3>
                        </div>
                      </Col>
                      <Col>
                        <Nav.Link
                          className="closeButton"
                          onClick={(e) => {
                            setShowCartPreview(true);
                          }}
                        >
                          <CloseIcon />
                        </Nav.Link>
                      </Col>
                    </Row>
                    <CartPreview />
                  </Container>
                </div>
              </div>
            </Nav>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default MainNavbar;
