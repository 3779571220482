import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Image,
  Nav,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getTransactionProducts } from "../../../api/products";
import { useDispatch, useSelector } from "react-redux";
import { removeItem_Cart } from "../../../Context/redux/cartSlice";

const CartPreview = () => {
  const cart_v2 = useSelector((state) => state.consumerCart.shoppingCart);
  const cart = cart_v2;
  const [itemUnavailableError, setItemUnavailableError] = useState(true);
  const [cartTotalError, setCartTotalError] = useState(false);
  var finalString = undefined;
  var cardInformation = null;
  var i = 1;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  if (cart.length > 0) {
    finalString = "ids=";
    cart.map((item) => {
      if (i < cart.length) {
        finalString = finalString + item._id + ",";
        i = i + 1;
      }

      // If this is the last object in the array then print the objust without the comma
      else {
        finalString = finalString + item._id;
      }
      return item;
    });
  }

  const setCardData = (data) => {
    cardInformation = data;
  };

  const { data, status } = useQuery({
    queryKey: ["cartProducts", "preview"],
    queryFn: () => getTransactionProducts(finalString),
  });

  if (status === "success") {
    setCardData(data.products);
  }

  const deleteProductFromCart = (item) => {
    dispatch(removeItem_Cart(item));
  };

  const calculateTotalCart = () => {
    let totalItems = 0;
    let totalPrice = 0;
    cart_v2.forEach((item) => {
      totalItems = totalItems + parseInt(item.cardDetails.cardQuantity);
      totalPrice =
        totalPrice + item.cardDetails.cardQuantity * item.cardDetails.cardPrice;
    });

    return {
      totalItems: totalItems,
      totalPrice: totalPrice,
    };
  };

  const navigateToCart = () => {
    navigate("/cart");
  };

  useEffect(() => {
    //queryClient.fetchQuery(["cartProducts", "preview"]);
  }, [cart_v2]);

  return (
    <>
      {cardInformation ? (
        <>
          {cardInformation.length !== 0 && cart_v2.length > 0 ? (
            <>
              <Row>
                {cartTotalError && (
                  <div className="error">
                    <span>Orders must be over $0.50</span>
                  </div>
                )}
                <Alert
                  className="error text-center"
                  hidden={itemUnavailableError}
                  dismissible
                  onClose={() => {
                    setItemUnavailableError(true);
                  }}
                >
                  Unfortunately, one or more of the items are no longer
                  available and have been removed. Please check cart before
                  continuing.
                </Alert>
              </Row>

              {cart_v2.map((item, index) => {
                let product = data.products.filter((card) => {
                  return card._id === item._id;
                });
                if (product.length < 1) {
                  return <></>;
                } else {
                  return (
                    <Container key={index}>
                      <Row className=" mb-3 border border-3 rounded-3 pt-2 pb-2 align-items-center">
                        <Row>
                          <Col></Col>
                          <Col className=" text-center">
                            <small>
                              <b>Name</b>
                            </small>
                          </Col>
                          <Col className=" text-center">
                            <small>
                              <b>Quantity</b>
                            </small>
                          </Col>
                          <Col className=" text-center">
                            <small>
                              <b>Total</b>
                            </small>
                          </Col>
                          <Col></Col>
                        </Row>
                        <Col>
                          <Image
                            src={`${product[0].images[0].small}`}
                            rounded={true}
                            style={{ width: "3rem" }}
                            onError={(event) => {
                              event.target.src =
                                "https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/404.png";
                              event.onerror = null;
                            }}
                          />
                        </Col>
                        <Col>
                          <div>{product[0].name}</div>
                          <small className=" fst-italic">
                            {item.cardDetails.cardQuailty}
                          </small>
                        </Col>
                        <Col>{item.cardDetails.cardQuantity}</Col>
                        <Col>
                          <span>
                            $
                            {(
                              item.cardDetails.cardPrice *
                              item.cardDetails.cardQuantity
                            ).toFixed(2)}
                          </span>
                        </Col>
                        <Col>
                          <Nav.Link
                            onClick={(e) => {
                              deleteProductFromCart(item);
                            }}
                          >
                            <DeleteIcon />
                          </Nav.Link>
                        </Col>
                      </Row>
                    </Container>
                  );
                }
              })}
              <Row>
                <Container>
                  <Row>
                    <Col>Items:</Col>
                    <Col className="text-end">
                      {calculateTotalCart().totalItems}
                    </Col>
                  </Row>
                  <Row>
                    <Col>Shipping:</Col>
                    <Col className="text-end">TBD</Col>
                  </Row>
                  <Row className=" border-bottom border-2 mb-3">
                    <Col>Tax:</Col>
                    <Col className="text-end">TBD</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <b>Total:</b>
                    </Col>
                    <Col className=" text-end">
                      <b>${calculateTotalCart().totalPrice.toFixed(2)}</b>
                    </Col>
                  </Row>
                </Container>
              </Row>
              <Row>
                <Button
                  variant="outline-warning"
                  onClick={() => {
                    navigateToCart();
                  }}
                >
                  Cart Summary
                </Button>
              </Row>
            </>
          ) : (
            <>
              <Row className=" mb-3 border border-3 rounded-3 pt-2 pb-2 text-center">
                <div className=" mb-3 pt-2 pb-2 text-center">
                  <div>
                    <img
                      src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteImages/emptyCart.png"
                      style={{ width: "300px" }}
                      alt="Empty Cart"
                      onError={(event) => {
                        event.target.src =
                          "https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/404.png";
                        event.onerror = null;
                      }}
                    />
                  </div>
                  Looks like there is nothing here, add some items and come
                  back.
                </div>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          (
          <>
            <Row className=" mb-3 border border-3 rounded-3 pt-2 pb-2 text-center">
              <div className=" mb-3 pt-2 pb-2 text-center">
                <div>
                  <img
                    src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteImages/emptyCart.png"
                    alt="Empty Cart"
                    onError={(event) => {
                      event.target.src =
                        "https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/404.png";
                      event.onerror = null;
                    }}
                  />
                </div>
                Looks like there is nothing here, add some items and come back.
              </div>
            </Row>
          </>
          )
        </>
      )}
    </>
  );
};

export default CartPreview;
