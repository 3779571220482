import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthContextProvider } from "./Context/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RecoilRoot } from "recoil";
import { Provider } from "react-redux";
import ErrorBoundary from "./Pages/ErrorBoundary";
import { persistor, store } from "./Context/redux/store";
import { PersistGate } from "redux-persist/integration/react";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <RecoilRoot>
          <ErrorBoundary fallback={"There has been an error"}>
            {" "}
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </ErrorBoundary>
        </RecoilRoot>
      </Provider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </AuthContextProvider>
);
