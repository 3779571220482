import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Link } from "react-router-dom";
import React from "react";

function Footer() {
  return (
    <>
      <Container
        fluid
        style={{ backgroundColor: "#FFB6CE", width: "100%", maxHeight: "30vh" }}
      >
        {/** Desktop Footer */}
        <Row className=" text-center d-lg-block d-none">
          <div className="mt-5 justify-items-center justify-content-center align-content-center d-inline-flex mb-2">
            <h3 className="header" style={{ color: "white" }}>
              CONNECT WITH US ON SOCIAL!
              <Link
                className="text-decoration-none"
                to="https://instagram.com/oddegggames?igshid=MzRlODBiNWFlZA=="
              >
                <img
                  src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/Instagram+Icon.svg"
                  alt="Instagram"
                  style={{
                    width: "4vh",
                    marginLeft: "2vh",
                    marginRight: "1vh",
                  }}
                />
              </Link>
              <Link
                className="text-decoration-none"
                to="https://twitter.com/OddEggGames?t=LhDt9DCjHthmXLna3YVYvw&s=09"
              >
                <img
                  src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/twitterx.png"
                  alt="X"
                  style={{ width: "5vh" }}
                  target="_blank"
                />
              </Link>
              <Link
                className="text-decoration-none"
                to="https://www.whatnot.com/user/oddegggames"
                target="_blank"
              >
                <img
                  src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/whatnot_logo.png"
                  alt="Whatnot"
                  target="_blank"
                  style={{
                    width: "4vh",
                    marginLeft: "1vh",
                  }}
                />
              </Link>
              <Link
                className="text-decoration-none"
                to="https://www.twitch.tv/odd_egg_games"
                target="_blank"
              >
                <img
                  src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/Twitch_Icon.png"
                  alt="Twitch"
                  target="_blank"
                  style={{
                    width: "3.3vh",
                    marginLeft: "1.3vh",
                    marginRight: "2vh",
                  }}
                />
              </Link>
            </h3>
          </div>
        </Row>

        {/** Mobile Footer */}
        <Row className=" text-center d-lg-none">
          <div className="mt-5 justify-items-center justify-content-center align-content-center d-inline-flex mb-2">
            <h3 className="header" style={{ color: "white" }}>
              <div className="header">CONNECT WITH US ON SOCIAL!</div>
              <div>
                <Link
                  className="text-decoration-none"
                  to="https://instagram.com/oddegggames?igshid=MzRlODBiNWFlZA=="
                >
                  <img
                    src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/Instagram+Icon.svg"
                    alt="Instagram"
                    style={{
                      width: "4vh",
                      marginLeft: "2vh",
                      marginRight: "2vh",
                    }}
                  />
                </Link>
                <Link
                  className="text-decoration-none"
                  to="https://twitter.com/OddEggGames?t=LhDt9DCjHthmXLna3YVYvw&s=09"
                >
                  <img
                    src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/twitterx.png"
                    alt="X"
                    style={{
                      width: "5vh",
                      marginLeft: "2vh",
                      marginRight: "2vh",
                    }}
                    target="_blank"
                  />
                </Link>
                <Link
                  className="text-decoration-none"
                  to="https://www.whatnot.com/user/oddegggames"
                  target="_blank"
                >
                  <img
                    src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/whatnot_logo.png"
                    alt="Whatnot"
                    target="_blank"
                    style={{
                      width: "4vh",
                      marginLeft: "2vh",
                      marginRight: "2vh",
                    }}
                  />
                </Link>
                <Link
                  className="text-decoration-none"
                  to="https://www.twitch.tv/odd_egg_games"
                  target="_blank"
                >
                  <img
                    src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/Twitch_Icon.png"
                    alt="Twitch"
                    target="_blank"
                    style={{
                      width: "3.3vh",
                      marginLeft: "2vh",
                      marginRight: "2vh",
                    }}
                  />
                </Link>
              </div>
            </h3>
          </div>
        </Row>
      </Container>
      <div>
        <footer className="py-4 flex-shrink-0  mt-auto">
          <Container
            fluid
            className=" m-4"
            style={{ backgroundColor: "#010036", width: "100%" }}
          >
            <Row className=" text-start">
              <Col className="text-start" lg={2}>
                <Row className="justify-content-center">
                  <h3 className="header" style={{ color: "#65ABF8" }}>
                    About Us
                  </h3>
                </Row>
                <Row>
                  <Link
                    className="text-decoration-none"
                    to="/legal/returnPolicy"
                    style={{ color: "white", fontSize: "18pt" }}
                  >
                    Refund and Return Policy
                  </Link>
                </Row>
                {/*<Row>
                  <Link
                    className="text-decoration-none"
                    to="/"
                    style={{ color: "white", fontSize: "18pt" }}
                  >
                    Contact Customer Service
                  </Link>
                </Row>*/}
                <Row>
                  <Link
                    className="text-decoration-none"
                    to="/legal/accessibilityPolicy"
                    style={{ color: "white", fontSize: "18pt" }}
                  >
                    Accessibility
                  </Link>
                </Row>
              </Col>
              <Col className="text-start">
                <Row>
                  <h3 className="header" style={{ color: "#A2DE88" }}>
                    Cards
                  </h3>
                </Row>
                <Row>
                  <a
                    className="text-decoration-none"
                    href="/products"
                    style={{ color: "white", fontSize: "18pt" }}
                  >
                    Products
                  </a>
                </Row>
                {/*<Row>
                  <Link
                    className="text-decoration-none"
                    to="/"
                    style={{ color: "white", fontSize: "18pt" }}
                  >
                    Buy List
                  </Link>
  </Row>*/}
              </Col>
            </Row>

            <Row className="mt-5">
              <div className="terms">
                <Col className="text-center">
                  <small style={{ color: "white", fontSize: "12pt" }}>
                    @2023 Odd Egg Games
                  </small>
                  <Row>
                    <Col className="text-center">
                      <Link
                        className="text-decoration-none"
                        to="/legal/privacyPolicy"
                      >
                        <small style={{ color: "white", fontSize: "12pt" }}>
                          Privacy Policy
                        </small>
                      </Link>
                      <Link
                        className="text-decoration-none m-3"
                        to="/legal/terms"
                      >
                        <small style={{ color: "white", fontSize: "12pt" }}>
                          Terms and Conditions
                        </small>
                      </Link>
                      <Link
                        className="text-decoration-none"
                        to="/legal/dataCollection"
                      >
                        <small
                          className=""
                          style={{ color: "white", fontSize: "12pt" }}
                        >
                          Website Data Collection{" "}
                        </small>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </div>
            </Row>
          </Container>
        </footer>
      </div>
    </>
  );
}

export default Footer;
