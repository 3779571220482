import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import React, { Suspense, useEffect } from "react";
import MainNavbar from "./Componets/Layout/Navbar";
import { useLogout } from "../src/Hooks/useLogout";
import Footer from "./Componets/Layout/Footer";
import ErrorBoundary from "./Pages/ErrorBoundary";
import ErrorPage from "./Pages/ErrorPage";
import ReactGA from "react-ga4";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import JWTDecoder from "./Componets/Utilities/JWTDecoder";
import { useSelector } from "react-redux";

const LazyHome = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/Home")), 100)
    )
);
const Lazy404 = React.lazy(
  () =>
    new Promise((res, rej) => setTimeout(() => res(import("./Pages/404")), 100))
);
const LazyMaintenance = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/Maintenance")), 100)
    )
);
const LazyFAQ = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/AboutUsPages/FAQ")), 100)
    )
);
const LazyTerms = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/LegalPages/TermsC")), 100)
    )
);
const LazyDataCollection = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/LegalPages/DataCollection")), 100)
    )
);
const LazyReturnPolicy = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/LegalPages/ReturnPolicy")), 100)
    )
);
const LazyAccessibilityPolicy = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(
        () => res(import("./Pages/LegalPages/AccessibilityPolicy")),
        100
      )
    )
);
const LazyPrivacyPolicy = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/LegalPages/PrivacyPolicy")), 100)
    )
);
const LazyProducts = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/ProductPages/ProductsHome")), 100)
    )
);
const LazyNewUser = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/AccountPages/NewUsers")), 100)
    )
);
const LazyUserLogin = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/AccountPages/UserLogin")), 100)
    )
);
const LazyUserLoginCart = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/AccountPages/UserLoginCart")), 100)
    )
);
const LazyUserVerifyEmail = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/AccountPages/EmailVerify")), 100)
    )
);
const LazyUserForgotPassword = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/AccountPages/ForgotPassword")), 100)
    )
);
const LazyWatchList = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/Watchlist")), 100)
    )
);
const LazyAccountHome = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/AccountPages/AccountHome")), 100)
    )
);
const LazyTradeFunction = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/AdminPages/TradeFunction")), 100)
    )
);
const LazyProductsInfoPage = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/ProductPages/ProductInfo")), 100)
    )
);
const LazyCartHome = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/CartPages/Cart")), 100)
    )
);
const LazyCheckout = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/CartPages/Checkout")), 100)
    )
);
const LazyConfirmationScreen = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/CartPages/ConfirmationScreen")), 100)
    )
);
const LazyEmailUpdated = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/AccountPages/EmailUpdated")), 100)
    )
);

const LazyAccountDeleted = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/AccountPages/AccountDeleted")), 100)
    )
);

const LazyOrderInformation = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/AdminPages/OrderInformation")), 100)
    )
);

const LazyInventory = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(
        () =>
          res(import("./Componets/Content/AdminContent/InventoryManagement")),
        100
      )
    )
);

const LazyAdminDashboard = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(
        () => res(import("./Componets/Content/AdminContent/AdminDashboard")),
        100
      )
    )
);

const LazyOrders = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(
        () => res(import("./Componets/Content/AdminContent/Orders")),
        100
      )
    )
);

const LazyTransactions = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(
        () => res(import("./Componets/Content/AdminContent/Transactions")),
        100
      )
    )
);

const LazyAutoPriceUpdate = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(
        () => res(import("./Componets/Content/AdminContent/PriceUpdate")),
        100
      )
    )
);

const LazyUserManagement = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(
        () => res(import("./Componets/Content/AdminContent/UserManagement")),
        100
      )
    )
);

const LazyMarketing = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(
        () => res(import("./Componets/Content/AdminContent/EmailMarketing")),
        100
      )
    )
);

const LazyBuylist = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/BuylistPages/Buylist")), 100)
    )
);

const LazyPOS = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/POS/POS")), 100)
    )
);

const LazyNewsletter = React.lazy(
  () =>
    new Promise((res, rej) =>
      setTimeout(() => res(import("./Pages/NewsletterSignUp")), 100)
    )
);


const maintenance = false;

function App() {
  const handleAcceptCookie = () => {
    ReactGA.initialize("G-7EN7CW0TS7");
    ReactGA._gtag("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
    });
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    Cookies.remove("_ga_F4FP2D4TE4");
    Cookies.remove("_ga_7EN7CW0TS7");
    ReactGA._gtag("consent", "update", {
      ad_storage: "denied",
      analytics_storage: "denied",
    });
  };

  const cart_v2 = useSelector((state) => state.consumerCart.shoppingCart);
  //console.log(cart_v2);
  const cart = cart_v2
  const { logout } = useLogout();
  var isExpired = false;
  var userData = null;

  const userHolder = JSON.parse(localStorage.getItem("user"));

  if (userHolder !== null) {
    const token = userHolder.token;
    userData = JWTDecoder(token);

    var dateNow = new Date();

    if (userData.exp * 1000 < dateNow.getTime()) {
      isExpired = true;
    }

    if (isExpired) {
      logout();
    }
  }

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        {maintenance ? (
          <>
            {/**
             * Maintenance Mode
             */}
            <div className="pages">
              <Routes>
                <Route
                  path="/"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyMaintenance />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />
              </Routes>
            </div>
          </>
        ) : (
          <>
            {/**
             * Live Version
             * */}
            <MainNavbar />
            <CookieConsent
              enableDeclineButton
              onAccept={handleAcceptCookie}
              onDecline={handleDeclineCookie}
              buttonText="I Accept"
              declineButtonText="I Decline"
              declineButtonStyle={{ backgroundColor: "#323f4a" }}
              buttonStyle={{ backgroundColor: "#8fc36d" }}
            >
              At Odd Egg Games, we use Cookies to ensure the best experince of
              our consumers, we use these to better your search, increase
              security, and ensure we provide the best experince possible. For
              more information see here (
              <a href="/legal/dataCollection">Data Collection</a>) .
            </CookieConsent>
            <div className="pages">
              <Routes>
                <Route
                  path="/"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      {userData !== null ? (
                        <ErrorBoundary fallback={<ErrorPage />}>
                          <LazyHome userID={userData.id} />
                        </ErrorBoundary>
                      ) : (
                        <ErrorBoundary fallback={<ErrorPage />}>
                          <LazyHome />
                        </ErrorBoundary>
                      )}
                    </Suspense>
                  }
                />

                <Route
                  path="/legal/terms"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyTerms />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />

                <Route
                  path="/about/FAQ"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyFAQ />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />

                <Route
                  path="*"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <Lazy404 />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />

                <Route
                  path="/legal/privacyPolicy"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyPrivacyPolicy />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />

                <Route
                  path="/legal/returnPolicy"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyReturnPolicy />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />

                <Route
                  path="/legal/accessibilityPolicy"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyAccessibilityPolicy />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />

                <Route
                  path="/legal/dataCollection"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyDataCollection />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />

                <Route
                  path="/products/:name"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyProducts />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />
                <Route
                  path="/products/set/:name"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyProducts />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />
                <Route
                  path="/products/"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyProducts />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />
                <Route
                  path="/users/signup"
                  element={
                    !userData ? (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <ErrorBoundary fallback={<ErrorPage />}>
                          <LazyNewUser />
                        </ErrorBoundary>
                      </Suspense>
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/users/login"
                  element={
                    !userData ? (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <ErrorBoundary fallback={<ErrorPage />}>
                          <LazyUserLogin />
                        </ErrorBoundary>
                      </Suspense>
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />

                <Route
                  path="/users/:id/verify/:token"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyUserVerifyEmail />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />
                <Route
                  path="/users/:id/forgotPassword/:token"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyUserForgotPassword />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />
                <Route
                  path="/users/login/cart"
                  element={
                    !userData ? (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <ErrorBoundary fallback={<ErrorPage />}>
                          <LazyUserLoginCart />
                        </ErrorBoundary>
                      </Suspense>
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />

                <Route
                  path="/users/watchlist"
                  element={
                    userData ? (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <ErrorBoundary fallback={<ErrorPage />}>
                          <LazyWatchList />
                        </ErrorBoundary>
                      </Suspense>
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyUserLogin />
                      </Suspense>
                    )
                  }
                />
                <Route
                  path="/users/accounthome"
                  element={
                    userData ? (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <ErrorBoundary fallback={<ErrorPage />}>
                          <LazyAccountHome />
                        </ErrorBoundary>
                      </Suspense>
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyUserLogin />
                      </Suspense>
                    )
                  }
                />

                <Route
                  //Route for the admin Portal
                  path="/admin/dashboard"
                  element={
                    userData ? (
                      userData.role === "Admin" ? (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <LazyAdminDashboard />
                          </ErrorBoundary>
                        </Suspense>
                      ) : (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <LazyHome />
                        </Suspense>
                      )
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyUserLogin />
                      </Suspense>
                    )
                  }
                />

                <Route
                  //Route for the admin Portal
                  path="/admin/inventory"
                  element={
                    userData ? (
                      userData.role === "Admin" ? (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <LazyInventory />
                          </ErrorBoundary>
                        </Suspense>
                      ) : (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <LazyHome />
                        </Suspense>
                      )
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyUserLogin />
                      </Suspense>
                    )
                  }
                />

                <Route
                  //Route for the admin Portal
                  path="/admin/orders"
                  element={
                    userData ? (
                      userData.role === "Admin" ? (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <LazyOrders />
                          </ErrorBoundary>
                        </Suspense>
                      ) : (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <LazyHome />
                        </Suspense>
                      )
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyUserLogin />
                      </Suspense>
                    )
                  }
                />

                <Route
                  //Route for the admin Portal
                  path="/admin/transactions"
                  element={
                    userData ? (
                      userData.role === "Admin" ? (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <LazyTransactions />
                          </ErrorBoundary>
                        </Suspense>
                      ) : (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <LazyHome />
                        </Suspense>
                      )
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyUserLogin />
                      </Suspense>
                    )
                  }
                />

                <Route
                  //Route for the admin Portal
                  path="/admin/autoUpdate"
                  element={
                    userData ? (
                      userData.role === "Admin" ? (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <LazyAutoPriceUpdate />
                          </ErrorBoundary>
                        </Suspense>
                      ) : (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <LazyHome />
                        </Suspense>
                      )
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyUserLogin />
                      </Suspense>
                    )
                  }
                />

                <Route
                  //Route for the admin Portal
                  path="/admin/marketing"
                  element={
                    userData ? (
                      userData.role === "Admin" ? (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <LazyMarketing />
                          </ErrorBoundary>
                        </Suspense>
                      ) : (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <LazyHome />
                        </Suspense>
                      )
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyUserLogin />
                      </Suspense>
                    )
                  }
                />

                <Route
                  //Route for the admin Portal
                  path="/admin/users"
                  element={
                    userData ? (
                      userData.role === "Admin" ? (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <LazyUserManagement />
                          </ErrorBoundary>
                        </Suspense>
                      ) : (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <LazyHome />
                        </Suspense>
                      )
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyUserLogin />
                      </Suspense>
                    )
                  }
                />

                <Route
                  //Route for the admin Portal
                  path="/trade"
                  element={
                    userData ? (
                      userData.role === "Admin" ||
                      userData.role === "Trader" ? (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <LazyTradeFunction />
                          </ErrorBoundary>
                        </Suspense>
                      ) : (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <LazyHome />
                        </Suspense>
                      )
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyUserLogin />
                      </Suspense>
                    )
                  }
                />

                <Route
                  //Route for the card information
                  path="/products/info/:_id"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyProductsInfoPage />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />

                <Route
                  //Route for the cart
                  path="/cart"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyCartHome />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />

                <Route
                  //Route for the cart
                  path="/checkout"
                  element={
                    cart.length > 0 ? (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <ErrorBoundary fallback={<ErrorPage />}>
                          <LazyCheckout />
                        </ErrorBoundary>
                      </Suspense>
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyHome />
                      </Suspense>
                    )
                  }
                />
                <Route
                  //Route for the cart
                  path="/checkout/confirm/:id"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyConfirmationScreen />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />

                <Route
                  //Route for the cart
                  path="/account/emailUpdated/:id"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyEmailUpdated />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />

                <Route
                  //Route for the cart
                  path="/account/accountDeleted/:id"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyAccountDeleted />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />

                <Route
                  //Route for the cart
                  path="/orderInformation/:id"
                  element={
                    userData ? (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <ErrorBoundary fallback={<ErrorPage />}>
                          <LazyOrderInformation />
                        </ErrorBoundary>
                      </Suspense>
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyUserLogin />
                      </Suspense>
                    )
                  }
                />

                <Route
                  //Route for the POS system
                  path="/admin/POS"
                  element={
                    userData ? (
                      userData.role === "Admin" || "POS User" ? (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <LazyPOS />
                          </ErrorBoundary>
                        </Suspense>
                      ) : (
                        <Suspense
                          fallback={
                            <div className=" text-center m-5">
                              <img
                                src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                                alt="Loading GIF"
                              />
                              <h3 className="header mt-4"> Loading...</h3>
                            </div>
                          }
                        >
                          <LazyHome />
                        </Suspense>
                      )
                    ) : (
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <LazyUserLogin />
                      </Suspense>
                    )
                  }
                />

                <Route
                  //Route for the cart
                  path="/buylist"
                  element={
                      <Suspense
                        fallback={
                          <div className=" text-center m-5">
                            <img
                              src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                              alt="Loading GIF"
                            />
                            <h3 className="header mt-4"> Loading...</h3>
                          </div>
                        }
                      >
                        <ErrorBoundary fallback={<ErrorPage />}>
                          <LazyBuylist />
                        </ErrorBoundary>
                      </Suspense>
                  }
                />
                <Route
                  //Route for the card information
                  path="/newsletter_signup/:promotionType"
                  element={
                    <Suspense
                      fallback={
                        <div className=" text-center m-5">
                          <img
                            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/OddEgg_Loading.gif"
                            alt="Loading GIF"
                          />
                          <h3 className="header mt-4"> Loading...</h3>
                        </div>
                      }
                    >
                      <ErrorBoundary fallback={<ErrorPage />}>
                        <LazyNewsletter />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />
              </Routes>
              <Footer />
            </div>
          </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
