import { createSlice } from "@reduxjs/toolkit";

/**
 * Sample Cart Data
 * [
  {
    "_id": "673e37f2b157247b013bec48",
    "name": "Exeggcute",
    "game": "Pokémon",
    "cardDetails": {
      "cardQuailty": "Non Holo NM Card",
      "cardQuantity": "6",
      "cardPrice": "0.25",
      "image": "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/sv8/1_hires_small.webp",
      "productType": "Card"
    }
  },
  {
    "_id": "673e37f2b157247b013bec4a",
    "name": "Exeggutor",
    "game": "Pokémon",
    "cardDetails": {
      "cardQuailty": "Non Holo LP Card",
      "cardQuantity": "6",
      "cardPrice": "0.20",
      "image": "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/sv8/3_hires_small.webp",
      "productType": "Card"
    }
  },
  {
    "_id": "673e37f2b157247b013bec4b",
    "name": "Durant ex",
    "game": "Pokémon",
    "cardDetails": {
      "cardQuailty": "Ultra Rare NM Card",
      "cardQuantity": "6",
      "cardPrice": "1.50",
      "image": "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/sv8/4_hires_small.webp",
      "productType": "Card"
    }
  },
  {
    "_id": "673e37f2b157247b013bec52",
    "name": "Zarude",
    "game": "Pokémon",
    "cardDetails": {
      "cardQuailty": "Holo NM Card",
      "cardQuantity": "6",
      "cardPrice": "0.30",
      "image": "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/sv8/11_hires_small.webp",
      "productType": "Card"
    }
  },
  {
    "_id": "673e37f2b157247b013bec52",
    "name": "Zarude",
    "game": "Pokémon",
    "cardDetails": {
      "cardQuailty": "Reverse Holo NM Card",
      "cardQuantity": "6",
      "cardPrice": "0.50",
      "image": "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/sv8/11_hires_small.webp",
      "productType": "Card"
    }
  },
  {
    "_id": "673e37f2b157247b013bed07",
    "name": "Exeggcute",
    "game": "Pokémon",
    "cardDetails": {
      "cardQuailty": "Ultra Rare NM Card",
      "cardQuantity": "6",
      "cardPrice": "6.04",
      "image": "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/sv8/192_hires_small.webp",
      "productType": "Card"
    }
  },
  {
    "_id": "673e37f2b157247b013bed1f",
    "name": "Scovillain ex",
    "game": "Pokémon",
    "cardDetails": {
      "cardQuailty": "Ultra Rare NM Card",
      "cardQuantity": "6",
      "cardPrice": "2.50",
      "image": "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/sv8/216_hires_small.webp",
      "productType": "Card"
    }
  },
  {
    "_id": "673e37f2b157247b013bed35",
    "name": "Pikachu ex",
    "game": "Pokémon",
    "cardDetails": {
      "cardQuailty": "Ultra Rare NM Card",
      "cardQuantity": "6",
      "cardPrice": "562.08",
      "image": "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/sv8/238_hires_small.webp",
      "productType": "Card"
    }
  },
  {
    "_id": "673e37f2b157247b013bed3e",
    "name": "Pikachu ex",
    "game": "Pokémon",
    "cardDetails": {
      "cardQuailty": "Ultra Rare NM Card",
      "cardQuantity": "6",
      "cardPrice": "175.00",
      "image": "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/sv8/247_hires_small.webp",
      "productType": "Card"
    }
  },
  {
    "_id": "63f3f03ecc5b58f1e241bc2d",
    "name": "Alakazam",
    "game": "Pokémon",
    "cardDetails": {
      "cardQuailty": "Unlimited NM Card",
      "cardQuantity": "6",
      "cardPrice": "53.95",
      "image": "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/base1_ul/001_small.webp",
      "productType": "Card"
    }
  },
  {
    "_id": "63f3f03ecc5b58f1e241bc2d",
    "name": "Alakazam",
    "game": "Pokémon",
    "cardDetails": {
      "cardQuailty": "First Edition NM Card",
      "cardQuantity": "6",
      "cardPrice": "852.50",
      "image": "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/base1_ul/001_small.webp",
      "productType": "Card"
    }
  },
  {
    "_id": "63f3f03ecc5b58f1e241bc2d",
    "name": "Alakazam",
    "game": "Pokémon",
    "cardDetails": {
      "cardQuailty": "Shadowless NM Card",
      "cardQuantity": "6",
      "cardPrice": "209.40",
      "image": "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/base1_ul/001_small.webp",
      "productType": "Card"
    }
  }
]

 */
const initialState = {
  buyListCart: [
    {
      _id: "673e37f2b157247b013bec4a",
      name: "Exeggutor",
      game: "Pokémon",
      cardDetails: {
        cardQuailty: "Non Holo LP Card",
        cardQuantity: "6",
        cardPrice: "0.20",
        image:
          "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/sv8/3_hires_small.webp",
        productType: "Card",
      },
    },
    {
      _id: "63f3f03ecc5b58f1e241bc2d",
      name: "Alakazam",
      game: "Pokémon",
      cardDetails: {
        cardQuailty: "First Edition NM Card",
        cardQuantity: "6",
        cardPrice: "852.50",
        image:
          "https://oddegg.s3.us-east-2.amazonaws.com/cardImages_small/base1_ul/001_small.webp",
        productType: "Card",
      },
    },
  ],
};

const buylistSlice = createSlice({
  name: "buyListCart",
  initialState,
  reducers: {
    addItem_Buylist: (state, action) => {
      return action.payload;
    },
    removeItem_Buylist: (state, action) => {},
    updateItem_Buylist: (state, action) => {},
    loginBuylistUpdate: () => {},
  },
});

export const {
  addItem_Buylist,
  removeItem_Buylist,
  updateItem_Buylist,
  loginBuylistUpdate,
} = buylistSlice.actions;

export default buylistSlice.reducer;
