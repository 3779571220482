import { clearAll_Cart } from "../Context/redux/cartSlice";
import { useAuthContext } from "./useAuthContext";
import { useDispatch } from "react-redux";

export const useLogout = () => {
  const { dispatch } = useAuthContext();
  const reduxDispatch = useDispatch()

  const logout = () => {
    // remove user from storage
    localStorage.removeItem("user");
    reduxDispatch(clearAll_Cart())

    //dispatch logout action
    dispatch({ type: "LOGOUT" });
  };

  return { logout };
};
